import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@styled-components';
import { FormattedMessage, intlShape, withIntl } from '@react-intl';

import Loader from 'Components/Loader';
import Country from 'Components/Country';

import Header from './Header';

const Container = styled('section')``;
const Card = styled('section')``;

const Title = styled('h2')``;
const Description = styled('p')``;
const Form = styled('form')``;
const Input = styled('input')``;
const Button = styled('button')``;
const Phone = styled('div')``;
const Value = styled('span')``;

class Demo extends Component {
  static propTypes = {
    className: PropTypes.string,
    intl: intlShape,
    location: PropTypes.shape({
      href: PropTypes.string,
    }),
  };

  state = { loading: false };

  componentDidMount() {
    const { location } = this.props;

    const url = new URL(location.href);
    const id = new URLSearchParams(url.search).get('id');

    this.setState({ id });
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;

    if (location.href !== prevProps.location.href) {
      const url = new URL(location.href);
      const id = new URLSearchParams(url.search).get('id');

      this.setState({ id });
    }
  }

  handleReset = () => {
    this.setState({ loading: false });
  };

  handleSubmit = async event => {
    event.preventDefault();
    const { id } = this.state;

    const data = new FormData(event.target);

    this.setState({ loading: true });

    const prefix = data.get('prefix').match(/\+\d+/)[0];

    await fetch(
      'https://hxcuzzns2j.execute-api.eu-west-1.amazonaws.com/default/sendmail',
      {
        body: JSON.stringify({
          email: data.get('email'),
          name: data.get('name'),
          companyName: data.get('companyName'),
          fromPage: 'Dennis Offer',
          phone: `${prefix}${data.get('phone')}`,
          ...(id && { id }),
        }),
        credentials: 'omit',
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
      },
    );

    window.location.href =
      '/tW96aWxsYS81LjAgKE1hY2ludG9zaDsgSW50ZWwgTWFjIE9TIFggMTBfMTRfNikgQXBwbGVXZWJLaXQvNTM3L';
  };

  render() {
    const { className, intl } = this.props;
    const { loading, id } = this.state;

    return (
      <section className={className}>
        <Header id={id} />
        <Container>
          <Title>Exclusive offer: Implementation fee waived</Title>
          <Card>
            <FormattedMessage
              capitalize
              component={Description}
              id="DEMO.INSTRUCTIONS"
            >
              fill in the following form more information and we will get in
              touch with you soon!
            </FormattedMessage>
            <Form onSubmit={this.handleSubmit}>
              <Input
                validate
                name="email"
                type="email"
                required="required"
                pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                placeholder={intl.formatMessage({
                  capitalize: true,
                  id: 'EMAIL',
                })}
              />
              <Input
                validate
                name="name"
                type="text"
                required="required"
                placeholder={intl.formatMessage({
                  capitalize: true,
                  id: 'NAME',
                })}
              />
              <Input
                validate
                name="companyName"
                type="text"
                required="required"
                placeholder={intl.formatMessage({
                  capitalize: true,
                  id: 'COMPANY_NAME',
                })}
              />
              <Phone>
                <Country />
                <Input
                  validate
                  name="phone"
                  type="tel"
                  placeholder={intl.formatMessage({
                    capitalize: true,
                    id: 'PHONE',
                  })}
                />
              </Phone>
              <Button>
                {loading ? (
                  <Loader />
                ) : (
                  <Value
                    capitalize
                    translate={
                      id ? 'ACTION.REQUEST_AUDIT' : 'ACTION.REQUEST_DEMO'
                    }
                  >
                    {id ? 'ACTION.REQUEST_AUDIT' : 'ACTION.REQUEST_DEMO'}
                  </Value>
                )}
              </Button>
            </Form>
          </Card>
        </Container>
      </section>
    );
  }
}

export default styled(withIntl(Demo))`
  background-image: linear-gradient(#003566, #003566),
    linear-gradient(240deg, #00284d, #003566);
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 35rem - 8.2rem);

  ${Container} {
    align-items: center;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 68vh;
    padding: 4rem;
  }

  ${Title} {
    color: red;
    font-size: 3rem;
    font-weight: 500;
    margin-bottom: 6rem;
  }

  ${Card} {
    background-color: #ffffff;
    border-radius: 0.8rem;
    box-shadow: 0 0.8rem 1.6rem 0 rgba(0, 53, 102, 0.04),
      0 0.2rem 0.4em 0 rgba(0, 53, 102, 0.08);
    padding: 4rem;
    max-width: 58.4rem;

    ${Description} {
      font-size: 1.6rem;
      line-height: 1.5;
      color: #000f19;
      margin-top: 0;
      opacity: 0.6;
    }

    ${Form} {
      display: grid;
      grid-template: auto;
      grid-gap: 1.6rem;
      width: 100%;
      margin-top: 3.2rem;

      ${Input} {
        background: none;
        border: solid 0.1rem #e6e7e8;
        border-radius: 0.6rem;
        cursor: auto;
        display: block;
        font-size: 1.4rem;
        height: 4.8rem;
        line-height: 1.14;

        outline: 0;
        padding: 1.6rem;

        ::placeholder {
          opacity: 0.6;
        }

        :-webkit-autofill,
        :-webkit-autofill:hover,
        :-webkit-autofill:focus :-webkit-autofill,
        :-webkit-autofill:hover {
          -webkit-box-shadow: 0 0 0 1000px transparent inset;
          transition: background-color 5000s ease-in-out 0s;
        }
      }

      ${Button} {
        background-color: #0084ff;
        border-radius: 0.6rem;
        color: white;
        cursor: pointer;
        font-size: 1.4rem;
        height: 4.8rem;
        margin-top: 4rem;
        outline: 0;
        width: 17.6rem;
      }

      ${Phone} {
        display: flex;
        height: 4.8rem;

        ${Input} {
          margin-left: 0.8rem;
          flex: 1;
        }
      }
    }

    ${Button} {
      background-color: white;
      border: solid 1px #0084ff;
      border-radius: 0.6rem;
      color: #0084ff;
      font-size: 1.4rem;
      margin-top: 8rem;
      height: 4rem;
      opacity: 0.8;
      outline: 0;
      width: 17.6rem;
    }
  }

  @media screen and (min-width: 600px) {
    ${Container} {
      padding: 0 11.2rem 8rem;
      height: 55vh;

      ${Form} {
        grid-template: repeat(4, auto) / 1fr 1fr;
        grid-gap: 1.6rem 2.4rem;

        ${Input} {
          &[type='email'] {
            grid-column: span 2;
          }
        }

        ${Button} {
          grid-row: 4;
          margin-top: 2.4rem;
          outline: 0;
          width: 17.6rem;
        }
      }
    }
  }

  @media screen and (min-width: 1920px) {
    ${Container} {
      padding: 8rem 20%;
      height: 55vh;
    }
  }
`;
